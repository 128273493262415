import React from 'react';
import { navigate } from 'gatsby';
import { Typography as T, Box, Flex, Button } from '@fuji-ui/core';
import { Wrapper } from '../components/layout';
import { FeatureCard as Card } from '../components/cards';
import {
  FeatureBlock,
  CenterBlock,
  WaveBlock,
  SmallScreenOnly,
} from '../components/block';
import heroImg from '../images/hero2.png';
// import remoteWorkImg from '../images/remote-work.png';
// import directoryImg from '../images/contacts.png';
// import chatsImg from '../images/hero.png';
import styled from 'styled-components';
import SEO from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
const HeroImg = styled(Box)`
  margin: auto;
  max-width: 1280px;
  @media (min-width: 480px) {
    background: url(${heroImg}) no-repeat;
    background-size: contain;
    background-position: right;
  }
`;
const Hero = (props) => (
  <HeroImg {...props}>
    <Wrapper>{props.children}</Wrapper>
  </HeroImg>
);
const Tagline = (props) => (
  <T.H1
    fontSize={['xl', 'xxl', null, null]}
    lineHeight={[1.18, 1.18, null, null]}
    {...props}
  />
);
const Highlighted = styled.span`
  background-color: white;
`;
const Index = (props) => {
  const toRequest = () => navigate('/contact-us');
  return (
    <>
      <SEO
        lang="en"
        title="Teamwork App: For remote team communication | Crafted by Talkbox"
      />
      <Hero>
        <Flex
          minHeight="360px"
          alignItems={['stretch', null, 'center', null]}
          flexDirection={['column', 'row-reverse', null, null]}
        >
          <Box flex={1} textAlign="center">
            <SmallScreenOnly>
              {/* <img src={heroImg} width="80%" /> */}
              <StaticImage
                src="../images/hero2.png"
                alt="Teamwork App: For remote team communication"
                style={{
                  width: '80%',
                  height: '80%',
                }}
              />
            </SmallScreenOnly>
          </Box>
          <Box mt="m" flex={2} textAlign={['center', 'left', null, null]}>
            <Tagline>
              <Highlighted>
                Every growing company faces communication hurdles.
              </Highlighted>
            </Tagline>
            <T.P>
              <Highlighted>
                Let's admit it - scattered email loops, inefficient workflow.
                You've been trying so hard to just keep things not out of
                control. We faced it too, and solve with{' '}
                <strong>Teamwork App</strong>.
              </Highlighted>
            </T.P>
            <Button mt="m" size="l" onClick={() => navigate('/features')}>
              Learn more
            </Button>
          </Box>
        </Flex>
      </Hero>
      <WaveBlock mt="l" pb="140px">
        <Box maxWidth="1680px" width="100%" m="auto" px={['s', 'm', 'l', null]}>
          {/* <img src={remoteWorkImg} width="100%" /> */}
          <StaticImage
            src="../images/remote-work.png"
            alt="Teamwork App"
            imgStyle={{ width: '100%' }}
          />
        </Box>
        <Wrapper>
          <Box
            as="section"
            m="auto"
            textAlign="center"
            maxWidth={[null, 860, 860, null]}
          >
            <T.H1>The tool for remote working team.</T.H1>
            <T.P>
              A response to the rapid business growth required by the new
              decade, Teamwork App is designed to reduce communication overhead,
              and keep team members on the same page. The team can start solving
              company problems collaboratively without learning. Remote team
              members can find staying connected to the team easier as messages
              are faster, becoming more responsive than using any other tools.
            </T.P>
          </Box>
        </Wrapper>
      </WaveBlock>
      <Box>
        <Wrapper>
          <FeatureBlock mt="m" flexDirection="column">
            <T.H1>
              90% of our clients report they have a more responsive team after
              using Teamwork App.
            </T.H1>
            <Flex
              alignItems={['stretch', null, 'center', null]}
              flexDirection={['column', null, 'row', null]}
            >
              <Box flex={1} mt="l">
                {/* <img src={chatsImg} width="100%" alt="" /> */}
                <StaticImage
                  src="../images/hero.png"
                  alt="The tool for remote working team."
                  imgStyle={{ width: '100%' }}
                />
              </Box>
              <Box flex={1} mt="l">
                <T.H3>Never missed a message</T.H3>
                <T.P>
                  Messages are instantly being notified and delivered to all
                  connected devices of the user. No matter your team is working
                  with their laptops, or moving with their phone, they will be
                  notified by important messages.
                </T.P>
                <T.H3>Stay organized while converations go fast</T.H3>
                <T.P>
                  Organize conversation by teams, projects or topics. Ease team
                  communication and keep stakeholders informed.
                </T.P>
              </Box>
            </Flex>
          </FeatureBlock>
        </Wrapper>
      </Box>
      <Box pt="l">
        <Wrapper>
          <CenterBlock mt="m" flexDirection="column">
            <T.H1>
              Handy Staff Directory is from where collaboration kick-started
            </T.H1>
            <Flex
              alignItems={['stretch', null, 'center', null]}
              flexDirection={['column', null, 'row-reverse', null]}
            >
              <Box flex={1} mt="l">
                {/* <img src={directoryImg} width="100%" alt="" /> */}
                <StaticImage
                  src="../images/contacts.png"
                  alt="Handy Staff Directory"
                  imgStyle={{ width: '100%' }}
                />
              </Box>
              <Box flex={1} mt="l">
                <T.H3>Setup your staff list once and for all.</T.H3>
                <T.P>
                  Forget about manually organizing and sharing staff contacts.
                  Contacts of new colleagues automatically appear and stay
                  organized.
                </T.P>
                <T.H3>You control how your teams were segregated.</T.H3>
                <T.P>
                  You can group the colleagues into teams and control how they
                  are going to interact with each other. Big corps require the
                  segregation of departments or business units to keep the
                  operations private within their team.
                </T.P>
              </Box>
            </Flex>
          </CenterBlock>
        </Wrapper>
      </Box>
      <Box pt="xl">
        <Wrapper>
          <T.H2>An Enterprise-ready Product</T.H2>
          <T.P>Own your copy of Teamwork App. Trusted by our clients.</T.P>
        </Wrapper>
        <Flex
          alignItems="stretch"
          flexDirection={['column', null, 'row', null]}
          width={['100%', '100%', '95%', '95%']}
          maxWidth="1280px"
          m="auto"
          mt="l"
        >
          <Card>
            <T.H4>Deploy Locally</T.H4>
            <T.P>
              VM-based containers make it super easy to deploy locally at your
              chosen data centers.
            </T.P>
          </Card>
          <Card>
            <T.H4>High Availability</T.H4>
            <T.P>
              With horizontal-scaling, Teamwork app achieves high availability
              through scaling more VMs in parallel.
            </T.P>
          </Card>
          <Card>
            <T.H4>Bank-grade Security</T.H4>
            <T.P>
              All app clients are managed remotely. With TLS/SSL, every single
              piece of your valuable data is encrypted.
            </T.P>
          </Card>
          <Card>
            <T.H4>LDAP/AD sync</T.H4>
            <T.P>
              Automatic user provisioning and SSO authentication right from
              organization LDAP or Active Directory.
            </T.P>
          </Card>
        </Flex>
      </Box>
      <Box textAlign="center" py="xl">
        <Wrapper>
          <Box m="auto" maxWidth={[null, 720, 720, null]}>
            <T.H2>We're offering special discount to non-profit entities.</T.H2>
            <T.P>
              Let us know if you're interested and get up to 50% discount by
              today.
            </T.P>
            <Button mt="m" size="l" onClick={toRequest}>
              Contact us
            </Button>
          </Box>
        </Wrapper>
      </Box>
    </>
  );
};
export default Index;
